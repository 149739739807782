



















import Vue from 'vue'
import Zondicon from 'vue-zondicons'

export default Vue.extend({
  name: 'BurgerMenu',
  components: {
    Zondicon,
  },
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    emitClose() {
      this.$emit('closeBurger')
    },
  },
})
