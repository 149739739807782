



































































































import Vue from 'vue'
import { Location, Route } from 'vue-router'
import Logo from '@/components/Logo.vue'
import BurgerMenu from '@/components/BurgerMenu.vue'
import LogoVariant from '@/utils/enum-logo-variant'
import Zondicon from 'vue-zondicons'

import { logOut } from '@/utils/authentication'
import { KitchenLightInDB } from '@/api-client/model/kitchen-light-in-db'
import { LiveSettingsT } from '@/interfaces/firestore'

export default Vue.extend({
  components: {
    Logo,
    Zondicon,
    BurgerMenu,
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      // Here, `vm` is an instance of type `SecondaryPage`.
      // We use a cast to an `any` to silence TS.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(vm as any).previousRoute = from
    })
  },
  beforeRouteUpdate(_to, from, next) {
    next()
    this.previousRoute = from
    // Close the burger menu after updating the route
    this.closeBurger()
  },
  data: () => ({
    isBurgerOpen: false,
    previousRoute: null as null | Route,
    isPauseModeActivated: false,
    firebaseUnsubscribe: null as null | (() => void),
  }),
  computed: {
    logoVariant: () => LogoVariant.DARK,
    lastSelectedKitchenID(): string | null {
      return this.$accessor.settings.lastSelectedKitchen
    },
    lastSelectedKitchen(): KitchenLightInDB | null {
      if (!this.lastSelectedKitchenID) return null
      return this.$accessor.cache.kitchen(this.lastSelectedKitchenID)
    },
    lastSelectedKitchenName(): string | null {
      return this.lastSelectedKitchen?.name ?? null
    },
    previousButtonRoute(): Location {
      return this.previousRoute && this.previousRoute.name === 'Home'
        ? Object.assign({}, this.previousRoute, {
            name: this.previousRoute.name ?? undefined,
          })
        : { name: 'Home' }
    },
  },
  mounted() {
    const kitchenID = this.$accessor.settings.lastSelectedKitchen
    if (!kitchenID) {
      this.$sentry.captureMessage(
        'Assertion failure: `lastSelectedKitchen` is `null`'
      )
      this.$accessor.auth.resetUser()
      this.$accessor.settings.resetKitchenSelection()
      this.$router.push('/login') // Force redirect to the log-in page
      return
    }

    this.firebaseUnsubscribe = this.$firebase
      .firestore()
      .collection('kitchens')
      .doc(kitchenID)
      .onSnapshot((kitchenDoc) => {
        const liveSettings: LiveSettingsT = kitchenDoc.data()
        this.isPauseModeActivated = !!liveSettings?.paused_since
      })
  },
  beforeDestroy() {
    if (this.firebaseUnsubscribe) this.firebaseUnsubscribe()
  },
  beforeMount() {
    // Ensure kitchens are up to date
    this.$accessor.cache.updateKitchens()
  },
  methods: {
    openBurger() {
      this.isBurgerOpen = true
    },
    closeBurger() {
      this.isBurgerOpen = false
    },
    async logOutAndRedirect() {
      await logOut()
      this.$router.go(0)
    },
  },
})
